.fade-in-enter {
  opacity: 0;
}
.fade-in-enter-active {
  opacity: 1;
}
.fade-in-exit {
  opacity: 1;
}
.fade-in-exit-active {
  opacity: 0;
}

.fade-in-up-appear {
  opacity: 0 !important;
  margin-top: 40px !important;
}

.fade-in-up-enter {
  opacity: 0 !important;
  margin-top: 40px !important;
}
.fade-in-up-enter-active {
  opacity: 1 !important;
  margin-top: 0 !important;
}
.fade-in-up-exit {
  opacity: 1 !important;
  margin-top: 40px !important;
}
.fade-in-up-exit-active {
  opacity: 0 !important;
  margin-top: 40px !important;
}
.fade-in-up-enter-done {
  margin-top: 0px !important;
  opacity: 1 !important;
}

// fade-enter, fade-enter-active, fade-enter-done, fade-exit, fade-exit-active, fade-exit-done, fade-appear,  fade-appear-active, and fade-appear-done.

.slide-up-enter {
  bottom: -100% !important;
}
.slide-up-enter-active {
  bottom: 0 !important;
}
.slide-up-exit {
  bottom: 0 !important;
}
.slide-up-exit-active {
  bottom: -100% !important;
}
.slide-up-enter-done {
  bottom: 0 !important;
}

.slide-in-left-enter {
  right: -100vw !important;
}
.slide-in-left-enter-active {
  right: 0 !important;
}
.slide-in-left-exit {
  right: 0 !important;
}
.slide-in-left-exit-active {
  right: -100vw !important;
}
.slide-in-left-enter-done {
  right: 0 !important;
}
