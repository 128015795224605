.tick {
  width: 60px;
  height: 60px;
  position: relative;
}
.tick-bg {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 100%;
  opacity: 0;
  -webkit-transform: rotateX(-180deg) scale(0.6);
  transform: rotateX(-180deg) scale(0.6);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  transition: all 0.25s ease-in-out;
}
.tick-bg:before,
.tick-bg:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  content: '';
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  opacity: 1;
  transition: all 0.25s ease-in-out;
}
.tick-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 100%;
}
.tick-icon svg {
  width: 24px;
  height: 24px;
  position: relative;
  top: 1px;
  fill: #1d53e0;
  opacity: 0;
  -webkit-transform: rotateX(-180deg) scale(0.6);
  transform: rotateX(-180deg) scale(0.6);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  transition: all 0.25s ease-in-out;
}
.tick.animating .tick-icon svg {
  -webkit-transform: rotateY(0deg) scale(1);
  transform: rotateY(0deg) scale(1);
  opacity: 1;
}
.tick.animating .tick-bg {
  -webkit-transform: rotateY(0deg) scale(1);
  transform: rotateY(0deg) scale(1);
  opacity: 1;
}
.tick.animating .tick-bg:before {
  width: 120px;
  height: 120px;
  transition-delay: 0.1s;
  opacity: 0;
}
.tick.animating .tick-bg:after {
  width: 120px;
  height: 120px;
  transition-delay: 0.25s;
  opacity: 0;
}

.play {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 60px;
}
