@import './reset';
@import './spinners';
@import './animatedCheck';
@import './animatedModals';
@import './animatedCar';
@import './fonts';
@import './adyen-form';

body {
  background-color: #092332;
}
