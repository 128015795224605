.adyen-checkout__payment-method,
.adyen-checkout__payment-method--selected {
  border: 0 !important;
  background: none !important;
}

.adyen-checkout__payment-method__details {
  padding: 0 !important;
}
.adyen-checkout__payment-method__header {
  padding: 8px 0px 5px 30px !important;
  line-height: 20px;
}

.adyen-checkout__label__text {
  color: #1b64f3 !important;
}

.adyen-checkout__input--focus,
.adyen-checkout__input--focus:hover,
.adyen-checkout__input:active,
.adyen-checkout__input:active:hover,
.adyen-checkout__input:focus,
.adyen-checkout__input:focus:hover {
  border: 1px solid #62d9e4 !important;
  box-shadow: none !important;
}

.adyen-checkout__button {
  background: #092332 !important;
}

.adyen-checkout__payment-method__image__wrapper--loaded {
  display: none !important;
}

.adyen-checkout__payment-method__radio {
  left: 0 !important;
}
