@mixin font($font-family, $font-file, $weight) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.woff') format('woff'),
      url($font-file+'.woff2') format('woff2'),
      url($font-file+'.svg') format('svg');
    font-weight: $weight;
    font-style: normal;
  }
}

@include font('F37 Ginger', '../fonts/ginger/bold/F37Ginger-Bold', 'bold');
@include font('F37 Ginger', '../fonts/ginger/regular/F37Ginger', 'regular');

* {
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'F37 Ginger', 'sans-serif';
}
a,
p,
span,
input,
textarea,
label,
li,
select {
  font-family: 'F37 Ginger', 'sans-serif';
  text-decoration: none;
}
